/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"
import "@fontsource/source-sans-pro/400.css"
import "@fontsource/open-sans/400.css"

import "./layout.css"

const navbarLinkStyle = {
    color: "hsla(0, 0%, 0%, 0.8)",
    textDecoration: "none",
    textAlign: 'right',
};

const navbarActiveLinkStyle = {
    fontWeight: "bold",
    fontSize: "18px",
};

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            display: `flex`,
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <nav style={{padding: '0 2rem', borderRight: '1px solid grey'}}>
              <h3 style={{whiteSpace: 'nowrap'}}> eric song </h3>
              <ul style={{ listStyleType: 'none' }}>
                  <li style={{ marginBottom: '0', textAlign: 'right' }}>
                      <Link to="/about" style={navbarLinkStyle} activeStyle={navbarActiveLinkStyle}> about </Link>
                  </li>
                  <li style={{ marginBottom: '0', textAlign: 'right' }}>
                      <Link to="/" style={navbarLinkStyle} activeStyle={navbarActiveLinkStyle}> posts </Link>
                  </li>
              </ul>
          </nav>
          <main style={{padding: '0 2rem'}}>{children}</main>
        </div>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
